<template>
  <div class="c-footer">
    <FHFooter hide-social />
  </div>
</template>
<script>
import FHFooter from '@forlagshuset/v-small-footer'

export default {
  components: {
    FHFooter,
  },
}
</script>

<style lang="scss">
.c-footer {
  @include bp(large) {
    margin: 0 -3.75rem;
  }

  .c-small-footer {
    background: transparent;
    margin: 0 auto;

    @include bp(large) {
      width: $large;
    }

    @include bp(xlarge) {
      width: $xlarge;
    }

    .c-logo {
      @include bp(large) {
        margin-left: 0;
      }
    }

    .c-small-footer__contact {
      @include bp(large) {
        margin-right: 0;
      }
    }

    svg path {
      fill: $fbf-color;
    }

    .c-contact-link__text {
      color: $fbf-color;
    }
  }
}
</style>
