<template>
  <div class="v-login">
    <div class="v-login__logo-wrapper">
      <TheLogo class="v-login__logo" @click.prevent="closeMenu('/')" />
    </div>
    <div class="v-login__wrapper">
      <picture class="v-login__pic">
        <img
          class="v-login__img"
          :alt="$t('LOGIN_IMAGE_TEXT')"
          :src="image.image"
        />
        <figcaption class="v-login__caption" v-html="image.caption" />
      </picture>
      <div class="v-login__container">
        <TheHeading level="h1" class="v-login__title">
          {{ $t('LOGIN_HEADING') }}
        </TheHeading>
        <div class="v-login__text" v-html="$t('LOGIN_TEXT')" />
        <TheButton class="v-login__btn" @click="login" type="login">
          {{ $t('LOGIN_BUTTON') }}
        </TheButton>
      </div>
    </div>
  </div>
  <TheFooter />
</template>

<script>
import { defineComponent } from 'vue'
import { useAuth, useTenant } from '@/core'
import TheLogo from '@/components/TheLogo.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import TheFooter from '@/components/TheFooter.vue'

export default defineComponent({
  name: 'Login',

  components: {
    TheLogo,
    TheHeading,
    TheButton,
    TheFooter,
  },

  setup() {
    const { login } = useAuth()
    const { tenant } = useTenant()

    const image = tenant.value.images.login

    return {
      login,
      image,
    }
  },
})
</script>

<style lang="scss">
.v-login {
  background-color: $login-bg;
  margin: -1.25rem -1.25rem 0;

  @include bp(large) {
    margin: -3.75rem;
    margin-bottom: 0;
  }
}

.v-login__logo-wrapper {
  padding: 1rem;
  text-align: center;

  @include bp(large) {
    text-align: left;
  }
}

.v-login__wrapper {
  margin: 4rem 1rem 0;
  max-width: calc(100% - 2rem);
  padding-bottom: 2rem;

  @include bp(large) {
    display: flex;
    flex-direction: column;
    flex-direction: row-reverse;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @include bp(xlarge) {
    width: $xlarge;
    margin-left: auto;
    margin-right: auto;
  }
}

.v-login__container {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;

  @include bp(small) {
    width: 75%;
  }

  @include bp(large) {
    width: 50%;
    margin-left: 0;
    text-align: left;
  }

  @include bp(xlarge) {
    width: rem(500px);
  }
}

.v-login__title {
  color: $login-title-text;
  font-size: 4rem;
  font-weight: 500;
  line-height: 4.6875rem;
  margin: 0;
}

.v-login__text {
  color: $login-text-text;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin: 1.5rem 0;

  h2 {
    color: $login-text-h2;
    font-weight: 500;
  }
}

.v-login__pic {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  margin: 0 auto;

  @include bp(medium) {
    width: 50%;
  }

  @include bp(xlarge) {
    width: rem(560px);
    margin-right: 0;
  }
}

.v-login__caption {
  margin: 0.25rem 0;
}
</style>
